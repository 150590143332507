<script setup>
import sanitizer from '@/util/htmlSanitizer'

import LoadingVue from '@/components/ui/Loading.vue'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TextViewerVue from '@/components/TextViewer.vue'

import { getLabMails, mailStatusModel, putLabMailStatus } from '@/api/labs'

const app = getCurrentInstance()
const getDate = app.appContext.config.globalProperties.$getDate

const isLoading = ref(false)
const mailData = ref(null)
const mailStatus = ref(0)
const preservedMailStatus = ref(0)
const boardData = ref({
  boardName: 'mail',
  boardId: 0
})

const route = useRoute()
const router = useRouter()

const goBack = () => {
  const path = route.fullPath.slice(0, route.fullPath.lastIndexOf('/'))
  router.push(path)
}

const loadData = async ({ labNo, mailId }) => {
  isLoading.value = true
  const data = await getLabMails(labNo, { mailId })

  mailData.value = data[0]
  mailData.value.createdAt = getDate(mailData.value.createdAt)
  mailStatus.value = Object.keys(mailStatusModel).find((key) => mailStatusModel[key] === mailData.value.status)
  preservedMailStatus.value = mailStatus.value

  isLoading.value = false
}

const statusChangeHandler = async (e) => {
  if (!confirm('진행상태를 변경하시겠습니까?')) {
    mailStatus.value = preservedMailStatus.value
    return
  }

  // api 호출
  const result = await putLabMailStatus(mailData.value.mailId, { status: mailStatus.value })
  if (result.code) {
    alert(result.message)
    mailStatus.value = preservedMailStatus.value
    return
  }

  alert('변경되었습니다.')
}

onMounted(async () => {
  boardData.value.boardId = route.params.mailId
  loadData({ labNo: route.params.labId, mailId: route.params.mailId })
})
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <form class="wf__admin-content" ref="formRef">
    <div class="wf__button-group">
      <button type="button" class="e-btn wf__btn btn-primary" @click="goBack">뒤로가기</button>
    </div>
    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>

        <tbody v-if="mailData">
          <tr>
            <th>제목</th>
            <td colspan="3">
              <span class="wf__td-title" v-html="`${sanitizer(mailData.title)}`"> </span>
            </td>
          </tr>

          <tr>
            <th>진행상태</th>
            <td colspan="3">
              <span class="wf__td-radios">
                <label class="wf__td-radio-item" :for="`status${key}`" v-for="(value, key) in mailStatusModel" :key="key">
                  <input type="radio" name="status" :value="key" :id="`status${key}`" v-model="mailStatus" @change="statusChangeHandler" />
                  <span v-html="sanitizer(value)"></span>
                </label>
              </span>
            </td>
          </tr>

          <tr>
            <th>보낸사람</th>
            <td colspan="3">{{ mailData.email }}</td>
          </tr>
          <tr>
            <th>연구실번호</th>
            <td>{{ mailData.labNo }}</td>
            <th>발송일</th>
            <td>{{ mailData.createdAt }}</td>
          </tr>
          <tr>
            <th>문의목적</th>
            <td colspan="3">{{ mailData.questionType }}</td>
          </tr>
          <tr>
            <th>문의내용</th>
            <td colspan="3">
              <TextViewerVue :propsData="mailData.content" :boardData="boardData" height="25em"></TextViewerVue>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.wf__admin-content {
  max-width: 1280px;
}
.wf__button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.adminTable th {
  text-align: center;
}
.adminTable td {
  padding: 0.5em 1.25em;
}

.wf__td-title {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.wf__td-radios {
  display: flex;
  align-items: center;
  gap: 1em;
}
.wf__td-radio-item {
  display: flex;
  align-items: center;
  gap: 0.25em;
}
</style>
